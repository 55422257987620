<template>
  <div>
    这是code{{code}}
  </div>
</template>

<script>
import { login } from '@/api'
import { getUrlParam } from '@/utils/getUrlQeury'
export default {
  name:'',
  components: {
  
  },
  props: {

  },
  data() {
    return {
      code: ''
    }
  },
  computed: {

  },
  watch: {

  },
  created() {

  },
  mounted() {
    let code = this.$route.query.code || getUrlParam('code');
    this.code = code
    // let state = this.$route.query.state || getUrlParam('state');
    // login({code}).then( res => {
    //   let role = res.data.role;
    //   localStorage.setItem('accessToken', res.data.accessToken)
    //   localStorage.setItem('role', role)
    //   this.$router.push(state);
    //   this.$message({
    //     message:'登录成功',
    //     type:'success',
    //     onClose(){
    //       _this.$router.push('/')
    //       localStorage.setItem('ifLoading', '1')
    //     }
    //   })
    // })
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">

</style>
